<template>
  <div class="mt-2">
    <button class="mb-2 btn btn-default btn-sm" @click="this.$router.go(-1)">
      Back
    </button>
    <div class="card shadow w-100 p-4 pb-6">
      <div class="row mb-4 text-center">
        <div class="col-md-12">
          <h3>Create Service Catalog</h3>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-md-6">
          <label class="form-control-label">Name</label>
          <span class="ml-1 text-danger">*</span>
          <input
            class="form-control form-control-alternative"
            placeholder="Please add the name of the catalog"
            v-model="name"
          />
          <label
            class="form-control-flush text-danger text-sm"
            v-if="nameError"
            >{{ nameError }}</label
          >
        </div>
      </div>
      <div class="row mt-4 mb-4">
        <div class="col-md-12">
          <h3>Please select Fields</h3>
          <hr class="my-1 " />
        </div>
      </div>
      <div class="row">
        <loader v-if="!data_loaded" />
      </div>


      <div class="row mb-4">
        <div
          class="col-md-6 mb-3"
          v-for="(fl, index) in all_fields"
          :key="index"
        >
          <label class="form-control-label"
            >{{ fl.named }} ({{ fl.type }})</label
          >

          <input
            class="form-control-alternative ml-2"
            v-if="fl"
            v-model="all_fields[index].selected"
            type="checkbox"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-md-8 mt-2">
          <button class="btn btn-secondary px-6" @click="this.$router.go(-1)">
            Cancel
          </button>
          <!-- <button-loader :title="btnTitle" :classes="classes" :show_loader="show_loader" @click="saveServiceCatalog()"/> -->
          <button
            :disabled="show_loader"
            class="btn btn-md btn-default my-2 px-4"
            :style="!show_loader ? '' : styleObject"
            @click="create_service_catalog()"
          >
            Add Service Catalog
            <i v-if="show_loader" class="fa fa-spinner fa-spin"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getDocs, collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { alert } from "../../utils/alertUtils";
import IndependentLoader from "../../components/IndependentDefaultLoader";

export default {
  data() {
    return {
      selected: 0,

      name: "",
      nameError: "",
      all_fields: [],
      fieldsSelected: [],
      data_loaded: false,
      // button styles
      styleObject: {
        cursor: "not-allowed",
      },
      btnTitle: "Add Service Catalog",
      show_loader: false,
      classes: "btn btn-md btn-default my-2 px-4",
    };
  },
    components: {
    loader: IndependentLoader,
  },
  methods: {
    create_service_catalog: function() {
      this.show_loader = true;
      // Loop through all fields and keep their object
      // in a single array of selected fields (fields selected)
      this.all_fields.forEach((fi) => {
        if (fi.selected === true) {
          this.fieldsSelected.push(fi);
        }
      });
      // console.log(this.fieldsSelected)
      if (this.name == "") {
        this.show_loader = false;
        this.nameError = "the name is required";
      } else {
        this.nameError = "";
        const colRef = collection(db, "serviceCatalog");
        addDoc(colRef, {
          fields: this.fieldsSelected,
          name: this.name,
        })
          .then(() => {
            alert.success_center("Service Catalog Added successfully.");
            this.show_loader = false;
            this.$router.push({ name: "Service Catalogs" });
          })
          .catch((e) => {
            this.show_loader = false;
            // TODO: add error method when update fail
            alert.error("An error occured");
            console.log(e);
          });
      }
    },
    fetch_fields: function() {
      getDocs(collection(db, "fields"))
        .then((snapShots) => {
          snapShots.forEach((document) => {
            let fields = document.data();
            this.all_fields.push(fields);
          });
        })
        .then(() => {
          this.data_loaded = true;
        })
        .catch(() => {
          this.data_loaded = false;
          alert.error("an error occured! please try again.");
        });
    },
  },

  created() {
    this.fetch_fields();
  },
};
</script>

<style lang="scss" scoped></style>
